import React from 'react';


import Navbar from './components/Navbar';
import Header from './sections/Header';
import Services from './sections/Services';
import Community from './sections/Community';
import OASection from './sections/OASection';
import Footer from './components/Footer';
import FAQ from './sections/FAQ';
import CPSection from './sections/CPSection';
import './App.css';

function App() {
  return (
    <div className="bg-[#FFF5F1] min-h-screen">
      <Navbar />
      <Header />
      <CPSection />
      <OASection />
      <Community />
        <Services />
        <FAQ />
        
      
      <Footer />
    </div>
  );
}

export default App;
