import React, { useState } from 'react';
import { motion } from 'framer-motion';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

const faqData = [
  {
    category: "General",
    icon: "🎯",
    questions: [
      {
        question: "What is helperr.io?",
        answer: "helperr.io is your tech career companion, providing support for coding interviews, contests, and job placements."
      },
      {
        question: "How does it work?",
        answer: "We provide real-time assistance, practice problems, and mentorship through our platform. Simply sign up and get started!"
      },
      {
        question: "Is it available 24/7?",
        answer: "Yes! Our platform is available round the clock to help you whenever you need it."
      }
    ]
  },
  {
    category: "Services",
    icon: "💻",
    questions: [
      {
        question: "What services do you offer?",
        answer: "We offer interview preparation, contest practice, job placement assistance, and personalized mentorship."
      },
      {
        question: "How much does it cost?",
        answer: "We offer various plans starting from free tier. Check our pricing page for more details."
      }
    ]
  }
];

export default function FAQ() {
  const [activeCategory, setActiveCategory] = useState("General");

  return (
    <section className=" py-24 text-white">
      <div className="container mx-auto px-4">
        {/* Section Title */}
        <div className="bg-white border-4 border-black p-6 shadow-brutal-lg max-w-2xl mx-auto mb-16 text-center">
          <h2 className="text-4xl font-bold text-black">
            Frequently Asked Questions
          </h2>
        </div>

        {/* Category Selector */}
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {faqData.map((category) => (
            <motion.button
              key={category.category}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveCategory(category.category)}
              className={`
                bg-yellow
                text-black
                ${activeCategory === category.category ? 'shadow-none translate-y-1' : 'shadow-brutal'}
                border-4
                border-black
                px-6
                py-3
                font-bold
                text-lg
                transition-all
                flex
                items-center
                gap-2
              `}
            >
              <span className="text-2xl">{category.icon}</span>
              {category.category}
            </motion.button>
          ))}
        </div>

        {/* FAQ Accordion */}
        <motion.div 
          layout
          className="max-w-3xl mx-auto"
        >
          {faqData.map((category) => (
            category.category === activeCategory && (
              <motion.div
                key={category.category}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="space-y-4"
              >
                {category.questions.map((faq, index) => (
                  <div
                    key={index}
                    className="bg-white border-4 border-black shadow-brutal hover:-translate-y-1 transition-transform"
                  >
                    <button
                      onClick={() => faq.isOpen = !faq.isOpen}
                      className="w-full p-6 text-left text-black font-bold text-xl flex justify-between items-center"
                    >
                      {faq.question}
                      <ChevronDown className="h-6 w-6 transition-transform" />
                    </button>
                    <div className="px-6 pb-6 text-black">
                      {faq.answer}
                    </div>
                  </div>
                ))}
              </motion.div>
            )
          ))}
        </motion.div>

        {/* Contact CTA */}
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="mt-16 text-center"
        >
          <p className="text-lg mb-4">Still have questions?</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white text-black border-4 border-black px-8 py-4 font-bold text-lg shadow-brutal hover:-translate-y-1 transition-transform"
          >
            Contact Support 💬
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
}