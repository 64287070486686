import React from 'react';

const Button = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={`
        bg-primary 
        text-white 
        border-4 
        border-black 
        px-8 
        py-4 
        text-xl 
        font-bold 
        shadow-brutal-lg
        hover:translate-y-1 
        hover:shadow-none 
        hover:bg-primary-dark
        transition-all
        ${className}
      `}
      {...props}
    >
      {children}
    </button>
  );
});

Button.displayName = 'Button';

export { Button }; 