import { useState } from 'react';
import { Button } from './ui/Button';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <nav className="sticky top-0 z-50 bg-[#FFF5F1] border-b-4 border-black">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <div className="flex items-center">
            <a href="/" className="bg-yellow-300 border-4 border-black px-4 py-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:-translate-y-1 transition-transform">
              <span className="font-bold text-xl">helperr.io</span>
            </a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <NavLink href="#services">Services</NavLink>
            <NavLink href="#community">Community</NavLink>
            <NavLink href="#about">About</NavLink>
            <Button className="bg-pink-300" onClick={() => setIsFormOpen(true)}>
              Contact Us
            </Button>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden bg-blue-300 border-4 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
          >
            <span className="text-2xl">☰</span>
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden border-t-4 border-black py-4">
            <div className="flex flex-col space-y-4">
              <MobileNavLink href="#services">Services</MobileNavLink>
              <MobileNavLink href="#community">Community</MobileNavLink>
              <MobileNavLink href="#about">About</MobileNavLink>
              <Button className="bg-pink-300 w-full" onClick={() => setIsFormOpen(true)}>
                Contact Us
              </Button>
            </div>
          </div>
        )}
      </div>

      {/* Popup Form */}
      {isFormOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
          <div className="bg-[#F0F0F0] p-8 rounded-lg shadow-lg border-4 border-black">
            <h2 className="font-bold text-2xl mb-4 text-center">Contact Us</h2>
            <form className="space-y-4">
              <input type="text" placeholder="Your Name" className="border-2 border-black p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
              <input type="email" placeholder="Your Email" className="border-2 border-black p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
              <textarea placeholder="Your Message" className="border-2 border-black p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" rows="4"></textarea>
              <Button type="submit" className="bg-pink-300 w-full p-3 rounded-md hover:bg-pink-400 transition duration-200">Send</Button>
              <button type="button" onClick={() => setIsFormOpen(false)} className="mt-2 text-red-500 underline">Close</button>
            </form>
          </div>
        </div>
      )}
    </nav>
  );
}

function NavLink({ href, children }) {
  return (
    <a
      href={href}
      className="font-bold hover:-translate-y-1 transition-transform inline-block border-b-4 border-transparent hover:border-black"
    >
      {children}
    </a>
  );
}

function MobileNavLink({ href, children }) {
  return (
    <a
      href={href}
      className="block font-bold py-2 px-4 bg-white border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:-translate-y-1 transition-transform"
    >
      {children}
    </a>
  );
} 