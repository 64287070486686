import React from 'react';
import { motion } from 'framer-motion';

export default function Services() {
  const services = {
    cp: {
      title: "CP Helper",
      subtitle: "Master Competitive Programming",
      description: "Get real-time assistance during live contests and practice sessions",
      features: [
        { icon: "🚀", text: "Live contest hints" },
        { icon: "🧩", text: "Problem-solving" },
        { icon: "⚡", text: "Time complexity" },
        { icon: "🔍", text: "Pattern recognition" },
        { icon: "💡", text: "Problem suggestions" }
      ],
      icon: "🏆",
    },
    oa: {
      title: "OA Helper",
      subtitle: "Ace Online Assessments",
      description: "Your companion for company-specific online coding assessments",
      features: [
        { icon: "🎯", text: "Company tips" },
        { icon: "📚", text: "Pattern guides" },
        { icon: "⏳", text: "Time management" },
        { icon: "✍️", text: "Mock assessments" },
        { icon: "📊", text: "Performance analytics" }
      ],
      icon: "📝",
    },
    placement: {
      title: "Placement Helper",
      subtitle: "Land Your Dream Job",
      description: "Complete preparation for technical interviews and placements",
      features: [
        { icon: "🎤", text: "Interview prep" },
        { icon: "📄", text: "Resume building" },
        { icon: "🤝", text: "Mock interviews" },
        { icon: "🏢", text: "Company resources" },
        { icon: "💰", text: "Salary tips" }
      ],
      icon: "💼",
    }
  };

  const ServiceCard = ({ service, serviceKey }) => (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      whileHover={{ scale: 1.02 }}
      transition={{ 
        duration: 0.5, 
        type: "spring",
        stiffness: 300
      }}
      className={`
        text-black
        border-4 
        border-black 
        p-6 sm:p-8 lg:p-10 
        shadow-brutal 
        hover:shadow-none
        transition-all
        duration-200
        relative
        overflow-hidden
        group
        rounded-lg
        flex-1
        mx-0 lg:mx-2
      `}
    >
      <div className="relative z-10">
        <div className="flex items-start justify-between mb-8">
          <div>
            <span className="text-6xl mb-4 block">{services[service].icon}</span>
            <h3 className="text-4xl font-bold mb-3">{services[service].title}</h3>
            <p className="font-medium text-xl">{services[service].subtitle}</p>
          </div>
          <div className="bg-primary-dark text-black px-5 py-3 rounded-none text-md font-bold">
            {serviceKey.toUpperCase()}
          </div>
        </div>

        <p className="text-xl mb-8 border-b-4 border-black pb-6">
          {services[service].description}
        </p>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          {services[service].features.map((feature, index) => (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ 
                duration: 0.3, 
                delay: index * 0.1,
                type: "spring",
                stiffness: 300
              }}
              key={index}
              className="
                
                text-primary
                border-4 
                border-black 
                p-5 
                flex 
                items-center 
                gap-4 
                hover:bg-white/90
                transition-all
                group-hover:opacity-90
                hover:opacity-100
                hover:translate-x-1
                hover:-translate-y-1
                cursor-pointer
                shadow-brutal
                hover:shadow-none
                rounded-lg  // Add rounded corners for curved design
              "
            >
              <span className="text-3xl">{feature.icon}</span>
              <span className="font-medium text-lg">{feature.text}</span>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );

  return (
    <section className="py-12 sm:py-16 lg:py-24" id="services">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-12 lg:mb-20"
        >
          <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 tracking-tight text-primary">
            Our Services
          </h2>
          <p className="text-lg sm:text-xl max-w-3xl mx-auto text-primary/80">
            Choose the perfect helper for your coding journey
          </p>
        </motion.div>

        {/* Updated flex container for responsiveness */}
        <div className="flex flex-col lg:flex-row gap-6 lg:space-x-4">
          {Object.keys(services).map((service) => (
            <ServiceCard 
              key={service} 
              service={service}
              serviceKey={service} 
            />
          ))}
        </div>
      </div>
    </section>
  );
} 