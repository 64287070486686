export default function Footer() {
  return (
    <footer className="bg-primary-bg dark:bg-dark-bg border-t-4 border-black py-16">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Brand Column */}
          <div className="space-y-4">
            <div className="bg-yellow-300 border-4 border-black px-4 py-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] inline-block">
              <span className="font-bold text-xl">helperr.io</span>
            </div>
            <p className="text-lg">
              Your Tech Career Companion
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="font-bold text-xl mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <FooterLink href="#services">Services</FooterLink>
              <FooterLink href="#community">Community</FooterLink>
              <FooterLink href="#about">About</FooterLink>
            </ul>
          </div>

          {/* Products */}
          <div>
            <h3 className="font-bold text-xl mb-4">Our Products</h3>
            <ul className="space-y-2">
              <FooterLink href="https://cp.helperr.io">CP Helper</FooterLink>
              <FooterLink href="https://oa.helperr.io">OA Helper</FooterLink>
              <FooterLink href="https://placement.helperr.io">Placement Helper</FooterLink>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="font-bold text-xl mb-4">Connect</h3>
            <div className="flex space-x-4">
              <SocialLink href="#" icon="𝕏" />
              <SocialLink href="#" icon="in" />
              <SocialLink href="#" icon="git" />
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-16 pt-8 border-t-4 border-black text-center">
          <p className="text-lg">
            © 2024 helperr.io. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

function FooterLink({ href, children }) {
  return (
    <li>
      <a
        href={href}
        className="hover:-translate-y-1 transition-transform inline-block border-b-2 border-transparent hover:border-black"
      >
        {children}
      </a>
    </li>
  );
}

function SocialLink({ href, icon }) {
  return (
    <a
      href={href}
      className="bg-blue-300 border-4 border-black w-10 h-10 flex items-center justify-center shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:-translate-y-1 transition-transform"
    >
      {icon}
    </a>
  );
}