// src/sections/OASection.js
import { motion } from 'framer-motion';
import { Button } from '../components/ui/Button';

export default function OASection() {
  return (
    <section className="bg-black text-white py-20 lg:py-32 px-4 relative overflow-hidden">
      {/* Site Navigation Button */}
      <div className="absolute top-4 right-4 z-50">
        <a 
          href="https://oa.helperr.io" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Button className="bg-[#00B4D8] text-white border-4 border-white hover:bg-[#0091B5] transform hover:-translate-y-1 hover:translate-x-1 shadow-[4px_4px_0px_0px_rgba(255,255,255,0.3)] px-6 py-3 text-lg font-bold">
            Visit OA Helper 💻
          </Button>
        </a>
      </div>

      {/* Background Dots - Updated for neobrutalism */}
      <div className="absolute inset-0 opacity-30">
        {[...Array(15)].map((_, i) => (
          <div
            key={i}
            className="absolute w-3 h-3 border-2 border-white rotate-12"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}
      </div>
      <div className="container mx-auto">
        {/* Section Header */}
        <div className="text-center mb-8 lg:mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl sm:text-5xl lg:text-7xl font-bold mb-4 lg:mb-6"
          >
            Latest OA Questions
          </motion.h2>
          <motion.p 
            className="text-lg sm:text-xl lg:text-2xl text-gray-400 px-4"
          >
            From Top Tech Companies • Updated Daily • Free Access
          </motion.p>
        </div>

        {/* Search Bar */}
        <motion.div className="max-w-3xl mx-auto mb-8 lg:mb-12 px-4">
          <div className="relative">
            <input 
              type="text"
              placeholder="Search companies & questions..."
              className="w-full bg-gray-900 border-4 border-white rounded-xl px-4 sm:px-6 py-3 sm:py-4 text-base sm:text-lg"
            />
            <span className="absolute right-4 top-1/2 -translate-y-1/2 text-xl sm:text-2xl">
              🔍
            </span>
          </div>
          <div className="flex flex-wrap gap-2 sm:gap-4 mt-4 justify-center">
            <Button className="bg-gray-800 text-white border-2 border-white px-4 sm:px-6 py-2 rounded-lg text-sm sm:text-base">
              All
            </Button>
            <Button className="bg-gray-800 text-white border-2 border-white px-4 sm:px-6 py-2 rounded-lg text-sm sm:text-base">
              Companies
            </Button>
            <Button className="bg-gray-800 text-white border-2 border-white px-4 sm:px-6 py-2 rounded-lg text-sm sm:text-base">
              Questions
            </Button>
          </div>
        </motion.div>

        {/* Questions Table */}
        <motion.div 
          className="bg-gray-900 border-4 border-white rounded-xl overflow-x-auto shadow-[8px_8px_0px_0px_rgba(255,255,255,0.3)]"
        >
          <table className="w-full min-w-[640px]">
            <thead className="border-b-4 border-white">
              <tr>
                <th className="px-4 sm:px-6 py-4 text-left">COMPANY</th>
                <th className="px-4 sm:px-6 py-4 text-left">DATE</th>
                <th className="px-4 sm:px-6 py-4 text-center">QUESTIONS</th>
                <th className="px-4 sm:px-6 py-4 text-center">SOLUTIONS</th>
              </tr>
            </thead>
            <tbody>
              {[
                { company: 'Axis Bank', date: 'Nov 28, 2024', questions: 1, available: true },
                { company: 'Cashfree', date: 'Nov 28, 2024', questions: 3, available: true },
                { company: 'CDK Global', date: 'Nov 28, 2024', questions: 2, available: true },
                { company: 'Chargebee', date: 'Nov 28, 2024', questions: 2, available: true }
              ].map((item, index) => (
                <motion.tr 
                  key={item.company}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="border-b border-gray-800 hover:bg-gray-800"
                >
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <div className="w-8 h-8 bg-gray-800 rounded-lg flex items-center justify-center">
                        🏢
                      </div>
                      {item.company}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div>
                      {item.date}
                      <div className="text-sm text-gray-500">15 days ago</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-center">{item.questions}</td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center">
                      <span className="bg-[#00B4D8] text-white px-4 py-1 rounded-lg text-sm">
                        AVAILABLE
                      </span>
                    </div>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      </div>
    </section>
  );
}