import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';

export default function Community() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const generateReview = () => ({
      pfp: faker.image.avatar(),
      fullName: faker.person.fullName(),
      jobTitle: faker.person.jobTitle(),
      review: faker.helpers.arrayElement([
        "helperr.io has been a game changer for my coding interviews!",
        "The hints during live contests are incredibly helpful.",
        "I found the job opportunities section very useful for my career.",
        "The community support is fantastic; I got answers to all my questions.",
        "The resources for online assessments are top-notch!",
        "I love how easy it is to navigate through the platform.",
      ]),
    });

    // Generate reviews in the same structure as the original
    const generatedReviews = [
      [generateReview(), generateReview()],
      [generateReview(), generateReview(), generateReview()],
      [generateReview(), generateReview()],
    ];

    setReviews(generatedReviews);
  }, []);

  return (
    <section className="bg-primary-bg dark:bg-dark-bg py-20">
      <div className="container mx-auto px-4">
        {/* Section Title */}
        <div className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] max-w-2xl mx-auto mb-16 text-center">
          <h2 className="text-4xl font-bold">
            Loved by the Community
          </h2>
        </div>

        {/* Reviews Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {reviews.map((column, columnIndex) => (
            <div key={columnIndex} className="space-y-6">
              {column.map((review, reviewIndex) => (
                <div
                  key={reviewIndex}
                  className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] hover:-translate-y-1 transition-transform"
                >
                  {/* User Info */}
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={review.pfp}
                      alt={review.fullName}
                      className="w-16 h-16 rounded-full border-4 border-black"
                    />
                    <div>
                      <h3 className="font-bold text-xl">{review.fullName}</h3>
                      <p className="text-gray-600">{review.jobTitle}</p>
                    </div>
                  </div>
                  
                  {/* Review Text */}
                  <p className="text-lg">
                    {review.review}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
} 