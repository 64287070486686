import { motion } from 'framer-motion';
import { Button } from '../components/ui/Button';

export default function CPSection() {
  return (
    <section className="bg-[#FFF5F1] py-20 lg:py-32 px-4 relative overflow-hidden">
      {/* Background Squares for Neobrutalism */}
      <div className="absolute inset-0 opacity-20">
        {[...Array(15)].map((_, i) => (
          <div
            key={i}
            className="absolute w-3 h-3 border-2 border-black rotate-12"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}
      </div>

      {/* Site Navigation Button */}
      <div className="absolute top-4 right-4 z-50">
        <a 
          href="https://cp.helperr.io" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Button className="bg-black text-white border-4 border-black hover:bg-gray-800 transform hover:-translate-y-1 hover:translate-x-1 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] px-6 py-3 text-lg font-bold">
            Visit CP Helper 🚀
          </Button>
        </a>
      </div>

      <div className="container mx-auto">
        {/* Section Header */}
        <div className="text-center mb-12 lg:mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl sm:text-5xl lg:text-7xl font-bold mb-6 text-black"
          >
            Live Contest Support
          </motion.h2>
          <motion.p 
            className="text-xl sm:text-2xl lg:text-3xl text-gray-800 max-w-3xl mx-auto"
          >
            Get instant help during live coding contests • 24/7 Support • Real-time Solutions
          </motion.p>
        </div>

        {/* Contest Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-12">
          {[
            { name: 'Codeforces Round #928', time: '2 hours', participants: '15K+' },
            { name: 'AtCoder Beginner 339', time: '4 hours', participants: '8K+' },
            { name: 'CodeChef Starters 124', time: '3 hours', participants: '12K+' }
          ].map((contest, index) => (
            <motion.div
              key={contest.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 1.02 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] hover:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all"
            >
              <h3 className="text-xl font-bold mb-4">{contest.name}</h3>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">⏰ {contest.time}</span>
                <span className="text-gray-600">👥 {contest.participants}</span>
              </div>
              <Button className="w-full mt-4 bg-[#00B4D8] text-white border-2 border-black hover:bg-[#0091B5] transform hover:-translate-y-1 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                Join Contest
              </Button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
} 