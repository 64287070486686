import { Button } from '../components/ui/Button';
import { motion } from 'framer-motion';

const LaptopFrame = ({ children }) => (
  <div className="relative mx-auto scale-90 lg:scale-100">
    <div className="relative border-4 border-black rounded-t-2xl h-[400px] w-[600px] bg-white overflow-hidden shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
      {children}
    </div>
    <div className="relative h-[30px] w-[600px] bg-black rounded-b-lg">
      <div className="absolute left-1/2 -translate-x-1/2 top-2 w-20 h-2 bg-gray-700 rounded-full" />
    </div>
  </div>
);

const PhoneFrame = ({ children }) => (
  <div className="relative mx-auto border-4 border-black rounded-[3rem] h-[500px] w-[250px] shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] bg-white overflow-hidden scale-75 lg:scale-90">
    <div className="absolute top-0 inset-x-0 h-6 bg-black rounded-b-3xl" />
    {children}
  </div>
);

export default function Header() {
  return (
    <header className="relative bg-[#FFE55C] dark:bg-dark-bg min-h-[60vh] lg:min-h-[110vh] flex items-center justify-center px-4 overflow-hidden py-20 lg:py-32 -mt-10 lg:-mt-20">
      <div className="container mx-auto grid lg:grid-cols-2 gap-12 lg:gap-16 items-center relative z-10">
        {/* Left Content */}
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="space-y-8 text-center lg:text-left"
        >
          <motion.div 
            whileHover={{ rotate: -4 }}
            className="inline-block bg-[#00B4D8] border-4 border-black px-4 lg:px-6 py-2 lg:py-3 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transform -rotate-2"
          >
            <p className="text-lg lg:text-xl font-bold text-white tracking-wide">Let's Capture Your</p>
          </motion.div>

          <div className="space-y-4">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-5xl sm:text-6xl lg:text-8xl font-bold leading-none"
            >
              <span className="block transform -rotate-[4deg] text-[#FF5C8D] drop-shadow-[4px_4px_0px_rgba(0,0,0,0.2)]">
                CODING
              </span>
              <motion.span 
                whileHover={{ rotate: 4 }}
                className="bg-[#FF5C8D] text-white border-4 border-black px-4 lg:px-6 py-2 inline-block mt-4 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] rotate-2"
              >
                JOURNEY!
              </motion.span>
            </motion.h1>
          </div>

          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="text-xl sm:text-2xl lg:text-3xl max-w-md mx-auto lg:mx-0 font-medium text-gray-800 leading-relaxed"
          >
            Get instant help with coding interviews, live contests, and job opportunities.
          </motion.p>

          {/* Service Buttons */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="flex flex-col sm:flex-row gap-4 items-center justify-center lg:justify-start"
          >
            <Button className="bg-[#00B4D8] text-white border-4 border-black hover:bg-[#0091B5] transform hover:-rotate-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] px-6 lg:px-8 py-3 lg:py-4 text-base lg:text-lg font-bold w-full sm:w-auto">
              CP Helper 🚀
            </Button>
            <Button className="bg-[#FF5C8D] text-white border-4 border-black hover:bg-[#FF3D7F] transform hover:-rotate-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] px-6 lg:px-8 py-3 lg:py-4 text-base lg:text-lg font-bold w-full sm:w-auto">
              OA Helper 💻
            </Button>
            <Button className="bg-[#FFE55C] text-black border-4 border-black hover:bg-[#FFD700] transform hover:-rotate-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] px-6 lg:px-8 py-3 lg:py-4 text-base lg:text-lg font-bold w-full sm:w-auto">
              Placement Helper 🎯
            </Button>
          </motion.div>
        </motion.div>

        {/* Right Content - Devices Display */}
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="relative mt-12 lg:mt-0"
        >
          {/* Laptop */}
          <div className="relative z-10 hidden md:block">
            <LaptopFrame>
              <div className="h-full p-6 bg-gradient-to-br from-gray-50 to-gray-100">
                <div className="flex items-center justify-between mb-6">
                  <div className="flex space-x-2">
                    <div className="w-3 h-3 rounded-full bg-red-500" />
                    <div className="w-3 h-3 rounded-full bg-yellow-500" />
                    <div className="w-3 h-3 rounded-full bg-green-500" />
                  </div>
                  <div className="bg-black text-white px-4 py-1 rounded-lg">
                    <p className="text-sm font-mono">helperr.io</p>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4">
                  {[
                    { name: 'CP Helper', color: '#00B4D8', icon: '🏆', desc: 'Master competitive programming' },
                    { name: 'OA Helper', color: '#FF5C8D', icon: '💻', desc: 'Ace online assessments' },
                    { name: 'Placement Helper', color: '#FFE55C', icon: '🎯', desc: 'Land your dream job' }
                  ].map((service, index) => (
                    <motion.div
                      key={service.name}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 + (index * 0.2) }}
                      className="relative bg-white border-2 border-black rounded-xl p-4 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
                      style={{ backgroundColor: service.color }}
                    >
                      <div className="text-2xl mb-2">{service.icon}</div>
                      <h3 className="font-bold text-white mb-1">{service.name}</h3>
                      <p className="text-sm text-white opacity-90">{service.desc}</p>
                    </motion.div>
                  ))}
                </div>

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                  className="mt-6 bg-gray-900 rounded-lg p-4"
                >
                  <div className="font-mono text-sm">
                    <span className="text-blue-400">function</span>{' '}
                    <span className="text-yellow-300">solveChallenge</span>
                    <span className="text-white">()</span> {'{'}
                    <span className="text-green-400"> // Start coding!</span>
                    {'}'}
                  </div>
                </motion.div>
              </div>
            </LaptopFrame>
          </div>

          {/* Phone */}
          <motion.div 
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="md:absolute -right-10 lg:-right-20 top-1/2 -translate-y-1/2 z-20"
          >
            <PhoneFrame>
              <div className="h-full pt-10 px-4 pb-4">
                <motion.div
                  initial={{ scale: 0.9 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="bg-black text-white px-4 py-2 rounded-xl mb-6 text-center"
                >
                  <h3 className="font-bold text-xl">helperr.io</h3>
                </motion.div>

                <div className="space-y-4">
                  {[
                    { name: 'CP Helper', color: '#00B4D8', icon: '🏆' },
                    { name: 'OA Helper', color: '#FF5C8D', icon: '💻' },
                    { name: 'Placement Helper', color: '#FFE55C', icon: '🎯' }
                  ].map((service, index) => (
                    <motion.div
                      key={service.name}
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      whileHover={{ scale: 1.05 }}
                      transition={{ delay: 0.6 + (index * 0.2) }}
                      className="bg-white border-2 border-black rounded-xl p-4 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
                      style={{ backgroundColor: service.color }}
                    >
                      <div className="flex items-center justify-between">
                        <h3 className="font-bold text-white">{service.name}</h3>
                        <span className="text-2xl">{service.icon}</span>
                      </div>
                    </motion.div>
                  ))}
                </div>

                <div className="absolute bottom-8 inset-x-4">
                  <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1.2 }}
                    className="bg-black text-white p-4 rounded-xl flex justify-around items-center"
                  >
                    <span className="text-xl">🏠</span>
                    <span className="text-xl">🔍</span>
                    <span className="text-xl">👤</span>
                  </motion.div>
                </div>
              </div>
            </PhoneFrame>
          </motion.div>

          {/* Decorative Elements */}
          <motion.div 
            whileHover={{ rotate: 24 }}
            className="absolute -top-8 -right-8 bg-[#FF5C8D] text-white border-4 border-black p-4 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] rotate-12 z-30 hidden lg:block"
          >
            <span className="text-4xl">💻</span>
          </motion.div>

          <motion.div 
            whileHover={{ rotate: -24 }}
            className="absolute -bottom-6 -left-6 bg-[#00B4D8] text-white border-4 border-black px-4 py-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] rotate-[-12deg] z-30 hidden lg:block"
          >
            <p className="text-xl font-bold">Code On! 🚀</p>
          </motion.div>
        </motion.div>
      </div>
    </header>
  );
} 